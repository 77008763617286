import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Alert, Button, Form, FormGroup, Input, InputGroup, InputGroupText } from 'reactstrap';
import { bindActionCreators } from 'redux';
import * as productAlertConfigActions from '../actions/productAlertConfigActions';
import ProductAlertBanner from '../components/common/productAlertBanner';
import config from '../config';
import { productIds } from '../constants/productAlertConfigValues';
import * as routes from '../routes';
import { PasswordInput } from './input';
import './loginForm.css';
import PrivacyPolicyAndTermsOfUseLinks from './legal/privacyPolicyAndTermsOfUseLinks';

export class LoginForm extends React.Component {
	state = {
		email: '',
		password: '',
	};

	showCurrentYear() {
		return new Date().getFullYear();
	}

	validateForm() {
		return this.state.email.length > 0 && this.state.password.length > 0;
	}

	handleChange = (event) => {
		const { name, value } = event.target;
		this.setState({ [name]: value });
	};

	handleSubmit = (event) => {
		event.preventDefault();
		if (this.isValid) {
			this.props.onSubmit(this.state.email, this.state.password);
		}
	};

	isValid() {
		return this.state.email && this.state.email.trim() && this.state.password && this.state.password.trim();
	};

	componentDidMount() {
		this.props.actions.productAlertConfig.getProductAlertConfig(productIds.RD2);
	}

	render() {
		return (
			<div className="loginWindow">
				{this.props.productAlertConfig.config?.isEnabled &&
					this.props.productAlertConfig.config?.alertMessage &&
					this.props.productAlertConfig.isActive && (
						<div className='loginAlertBanner'>
							<ProductAlertBanner config={this.props.productAlertConfig.config} />
						</div>
					)}
				<Form onSubmit={this.handleSubmit} className="loginForm">
					<img src={this.props.logoUrl} alt="Logo" className="loginLogo" />
					{this.props.error && !this.props.isLoading && <Alert color="danger">{this.props.error}</Alert>}
					<FormGroup>
						<InputGroup>
							<InputGroupText
								style={{
									border: '1px solid #ced4da',
									borderRadius: '.25rem',
									borderRight: 'none',
									marginRight: '-4px',
									padding: '0px',
									backgroundColor: 'transparent'
								}}
							>
								<span
									color="default"
									style={{
										cursor: 'inherit',
										margin: 'auto',
									}}
									className="btn btn-default btn-sm bg-transparent"
								>
									<FontAwesomeIcon icon="envelope" />
								</span>
							</InputGroupText>
							<Input
								type="email"
								name="email"
								placeholder="Email"
								value={this.state.email}
								onChange={this.handleChange}
								className="py-2 border-left-0 border"
							/>
						</InputGroup>
					</FormGroup>
					<PasswordInput label="Password" name="password" placeholder="Password" onChange={this.handleChange} />
					<FormGroup>
						<Button color="link" onClick={() => this.props.forgotPassword()}>
							Forgot Password?
						</Button>
					</FormGroup>
					<FormGroup className="loginButton">
						<Button type="submit" className="experian-btn" block disabled={this.props.isLoading}>
							{this.props.isLoading ? (
								<span>
									<FontAwesomeIcon icon="spinner" spin /> Logging in...
								</span>
							) : (
								<span>Login</span>
							)}
						</Button>
					</FormGroup>
					{(this.props.externalAuth.enableSingleSignOn && this.props.externalAuth.isAutoLoginEnabled === false) ? (
						<>
							<FormGroup style={{ textAlign: "center" }}>
								<span>Or</span>
							</FormGroup>
							<FormGroup>
								<Button
									className="ssoButton"
									block
									onClick={this.props.handleSso}
								>
									<span>SSO Login</span>
								</Button>
							</FormGroup>
						</>) : ('')
					}
					<div className="loginFooter">
						<PrivacyPolicyAndTermsOfUseLinks />
						<div className="text-center loginFooterSize">
							@ {this.showCurrentYear()} MyHealthDirect, Inc. All Rights Reserved.
						</div>
					</div>
					<div className="text-center loginContactUs">
						Need Help? Please contact us at (629) 777-5552 |{' '}
						{config.version}
					</div>
				</Form>
			</div>
		);
	}
}

LoginForm.propTypes = {
	email: PropTypes.string,
	error: PropTypes.string,
	isLoading: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
	return {
		productAlertConfig: state.productAlertConfig,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		forgotPassword: () => dispatch(routes.forgotPassword()),
		actions: {
			productAlertConfig: bindActionCreators(productAlertConfigActions, dispatch),
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
