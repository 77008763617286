import * as React from 'react';
import PropTypes from 'prop-types';
import { PasswordInput } from '../input'

import {
	Alert,
	Button,
	Col,
	Form,
	FormGroup,
	Label,
	Row,
} from 'reactstrap';

export default class ChangePassword extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			currentPassword: '',
			newPassword: '',
			confirmPassword: '',
			isCurrentNotProvided: false,
			isNotLongEnough: false,
			isSameAsCurrent: false,
			isNotConfirmed: false,
		}

		this.onSubmit = this.onSubmit.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.validatePasswords = this.validatePasswords.bind(this);
		this.validateField = this.validateField.bind(this);
	}

	onSubmit = (event) => {
		event.preventDefault();
		var fieldsValid = this.validatePasswords();
		if (fieldsValid) {
			this.props.onSubmit(this.state.currentPassword, this.state.newPassword, this.state.confirmPassword)
		}
	}

	handleCancel = (event) => {
		event.preventDefault();
		this.setState({
			currentPassword: '',
			newPassword: '',
			confirmPassword: '',
		});

		if (this.props.onCancel) {
			this.props.onCancel();
		}
	}

	validatePasswords = () => {
		var validationSet = {
			isCurrentPasswordValid: this.validateField("currentPassword", this.state.currentPassword),
			isNewPasswordValid: this.validateField("newPassword", this.state.newPassword),
			isConfirmPasswordValid: this.validateField("confirmPassword", this.state.confirmPassword),
		}
		return Object.keys(validationSet).every(function (x) {
			return validationSet[x] === true;
		})
	}

	validateField = (field, value) => {
		switch (field) {

			case "currentPassword":
				let isCurrentProvided = !!value;
				this.setState({ isCurrentNotProvided: !isCurrentProvided });
				return isCurrentProvided;

			case "newPassword":
				let isNotLongEnough = !value || value.length < this.props.passwordConfig.requiredLength;
				let isSameAsCurrent = value === this.state.currentPassword;
				this.setState({
					isNotLongEnough: isNotLongEnough,
					isSameAsCurrent: isSameAsCurrent,
				});
				return !(isNotLongEnough || isSameAsCurrent);

			case "confirmPassword":
				let isConfirmed = this.state.newPassword === this.state.confirmPassword;
				this.setState({ isNotConfirmed: !isConfirmed });
				return isConfirmed;

			default:
				return true;
		}
	}

	checkString = (source, subString) => {
		var ret = false;
		var index = -1;

		index = source.indexOf(subString);
		if (index > -1) {
			ret = true;
		}
		return ret;
	}

	handleChange = (event) => {
		const { name, value } = event.target;
		this.setState({ [name]: value });
	}

	render() {
		const isCurrentNotProvidedStyle = {
			display: this.state.isCurrentNotProvided ? "block" : "none",
		}

		const isNotLongEnoughStyle = {
			display: this.state.isNotLongEnough ? "block" : "none",
		}

		const isSameAsCurrentStyle = {
			display: this.state.isSameAsCurrent ? "block" : "none",
		}

		const isNotConfirmedStyle = {
			display: this.state.isNotConfirmed ? "block" : "none",
		}

		const successStyle = {
			width: '100%',
			textAlign: 'center',
		}

		const errorStyle = {
			width: '100%',
		}

		return (
			<div style={{ padding: '20px' }}>
				<Row>
					<Col>
						<Row>
							<Col>
								<p>
									As a HITRUST Certified company, we are committed to HIPAA compliance and the privacy and integrity of
									all patients and their data. All passwords must contain at least{' '}
									{this.props.passwordConfig.requiredLength} characters,
									{this.props.passwordConfig.requireUppercase && ' 1 capital letter,'}
									{this.props.passwordConfig.requireLowercase && ' 1 lower case letter,'}
									{this.props.passwordConfig.requireDigit && ' 1 number,'}
									{this.props.passwordConfig.requireNonAlphanumeric && ' 1 special character,'}
									&nbsp;and new passwords cannot be one of your last {this.props.passwordConfig.passwordHistoryCount} passwords.
								</p>
							</Col>
						</Row>
						<Row>
							<Col className="d-flex justify-content-center">
								{this.props.response && this.props.response.errors.length > 0 ?
									<Alert color="danger" style={errorStyle}>
										<ul>
											{this.props.response.errors.map((e, i) => {
												return (
													<li key={i}>
														<div className="invalid-feedback" style={{ display: "inline" }}>{e}</div>
													</li>
												)
											})}
										</ul>
									</Alert>
									: this.props.response && this.props.response.succeeded ?
										<Alert color="success" style={successStyle}>Your password has been changed successfully.</Alert>
										: ''}
							</Col>
						</Row>
					</Col>
				</Row>
				<Row>
					<Col>
						<Form onSubmit={this.onSubmit}>
							<Row>
								<Col lg="3">
									<FormGroup>
										<Label for="currentPassword" className="fw-bold">CURRENT PASSWORD</Label>
										<PasswordInput label="Password" name="currentPassword" id="currentPassword"
											onChange={this.handleChange} value={this.state.currentPassword} />
										<div className="invalid-feedback" style={isCurrentNotProvidedStyle}>You must provide your current password.</div>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col lg="3">
									<FormGroup>
										<Label for="newPassword" className="fw-bold">NEW PASSWORD</Label>
										<PasswordInput label="New Password" name="newPassword" id="newPassword"
											onChange={this.handleChange} value={this.state.newPassword} />
										<div className="invalid-feedback" style={isNotLongEnoughStyle}>Your new password must be at least {this.props.passwordConfig.requiredLength} characters.</div>
										<div className="invalid-feedback" style={isSameAsCurrentStyle}>Your new password can't match your current one.</div>

									</FormGroup>
								</Col>
								<Col lg="3">
									<FormGroup>
										<Label for="confirmPassword" className="fw-bold">CONFIRM PASSWORD</Label>
										<PasswordInput label="Confirm Password" name="confirmPassword" id="confirmPassword"
											onChange={this.handleChange} value={this.state.confirmPassword} />
										<div className="invalid-feedback" style={isNotConfirmedStyle}>You must confirm your new password.</div>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col>
									<Button type="submit" color="primary" style={{ marginRight: '10px' }}>Save</Button>
									{this.props.showCancel &&
										<Button onClick={this.handleCancel} style={{ marginRight: '10px' }}>Cancel</Button>
									}
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
			</div>
		)
	}
}

ChangePassword.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	onCancel: PropTypes.func,
	showCancel: PropTypes.bool,
	response: PropTypes.shape({
		succeeded: PropTypes.bool,
		errors: PropTypes.array,
	}),
}
