import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { Card, CardBody, CardHeader, Col, Collapse, Row } from 'reactstrap';
import { convertStatusCode } from '../../../lib/careOrderUtils';
import { LabeledField } from '../../../components/common/standardComponents';
import { useSelector } from 'react-redux';
import { useState } from 'react';

export default function CareOrderDetails(props) {

    const hasCareOrder = useSelector(state => !!state.careOrder.careOrderVisitIdentifier || !!state.careOrder.externalReferralOrderId);
    const enableOrderDetailsPanel = useSelector(state => state.config.orderScheduling.enableOrderDetailsPanel);
    const autoExpandOrderDetailsPanel = useSelector(state => state.config.orderScheduling.autoExpandOrderDetailsPanel);

    const details = useSelector(state => state.careOrder.activeCareOrderDetails);
    const fieldConfig = useSelector(state => state.config.careOrderPanel);

    const [careOrderFormOpen, setCareOrderFormOpen] = useState(autoExpandOrderDetailsPanel);
    const isCareOrderHeaderCollapsed = !careOrderFormOpen;

    if (!enableOrderDetailsPanel || !hasCareOrder) {
        return null;
    }

    let panelDetails = {
        statusCode: details?.statusCode,
        statusDisplayName: details?.statusDisplayName,
        careOrderType: details?.careOrderType,
        orderDate: details?.orderDate ? moment(details?.orderDate).format('LL') : null,
        expirationDate: details?.expirationDate ? moment(details?.expirationDate).format('LL') : null,
        minVisitDate: details?.minVisitDate ? moment(details?.minVisitDate).format('LL') : null,
        maxVisitDate: details?.maxVisitDate ? moment(details?.maxVisitDate).format('LL') : null,
        siteName: details?.appointments[0].siteName,
        serviceDisplayName: details?.appointments[0].serviceDisplayName,
        referringSiteName: details?.appointments[0].referringSiteName,
        referringServiceName: details?.appointments[0].referringServiceName,
        specialtyName: details?.appointments[0].specialtyName,
        subgroupName: details?.subgroupName,
        appointmentTypeName: details?.appointments[0].appointmentTypeName,
        externalOrderNumber: details?.appointments[0].externalReferralOrderId,
        reasonForVisit: details?.appointments[0].reasonForVisit,
        productInstanceConsumerName: details?.productInstanceConsumerName,
        notes: details?.notes,
    };

    const orderedFields = [
        { config: fieldConfig.status, value: 'statusCode', type: null, conversionFunc: (code) => convertStatusCode(code) },
        { config: fieldConfig.statusReason, value: 'statusDisplayName', type: null },
        { config: fieldConfig.careOrderType, value: 'careOrderType', type: null },
        { config: fieldConfig.externalOrderNumber, value: 'externalOrderNumber', type: null },
        { config: fieldConfig.orderDate, value: 'orderDate', type: null },
        { config: fieldConfig.expirationDate, value: 'expirationDate', type: null },
        { config: fieldConfig.minDate, value: 'minVisitDate', type: null },
        { config: fieldConfig.maxDate, value: 'maxVisitDate', type: null },
        { config: fieldConfig.site, value: 'siteName', type: null },
        { config: fieldConfig.service, value: 'serviceDisplayName', type: null },
        { config: fieldConfig.referringSite, value: 'referringSiteName', type: null },
        { config: fieldConfig.referringService, value: 'referringServiceDisplayName', type: null },
        { config: fieldConfig.specialty, value: 'specialtyName', type: null },
        { config: fieldConfig.subgroup, value: 'subgroupName', type: null },
        { config: fieldConfig.appointmentType, value: 'appointmentTypeName', type: null },
        { config: fieldConfig.reasonForVisit, value: 'reasonForVisit', type: null },
        { config: fieldConfig.productInstanceConsumer, value: 'productInstanceConsumerName', type: null },
    ];

    const visibleFields = orderedFields.filter((x) => x.config.isVisible);
    const numberOfColumns = 3;

    const shownFields = visibleFields.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / numberOfColumns);
        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = Array(numberOfColumns).fill({ config: null, value: null, type: null });
        }
        resultArray[chunkIndex][index % numberOfColumns] = item;
        return resultArray;
    }, []);

    let chevron = (
        <FontAwesomeIcon
            style={{ marginLeft: '10px' }}
            icon={isCareOrderHeaderCollapsed ? faChevronRight : faChevronDown}
            size="sm"
        />
    );

    return (
        <div className="form-container">
            <Card>
                <CardHeader>
                    <h4 onClick={() => setCareOrderFormOpen(x => !x)} className="hand" style={{ marginBottom: '0px' }}>
                        Order Details {chevron}
                    </h4>
                </CardHeader>
                <Collapse isOpen={careOrderFormOpen}>
                    <CardBody>
                        <>
                            {shownFields.map((chunk, index) => {
                                return (
                                    <Row key={index}>
                                        {chunk.map((x, index) => {
                                            return x.config ? (
                                                <Col key={index}>
                                                    <LabeledField label={x.config.fieldLabel} value={panelDetails[x.value]} />
                                                </Col>
                                            ) : (
                                                <Col key={index}></Col>
                                            );
                                        })}
                                    </Row>
                                );
                            })}
                            {fieldConfig.notes.isVisible && (
                                <Row>
                                    <Col>
                                        <LabeledField label={fieldConfig.notes.fieldLabel} value={details?.notes} />
                                    </Col>
                                </Row>
                            )}
                        </>
                    </CardBody>
                </Collapse>
            </Card>
        </div>
    );
}