//Third Party Dependencies
import React from 'react';
import { connect } from 'react-redux';
import {
    Col,
    Container,
    Row
} from 'reactstrap';

//MHD General Purpose libraries
import LoadingIndicator from '../../components/loadingIndicator';

//Specific Business and UI components
import * as routes from '../../routes';
import { logoutUser, saveFirstLoginChanges } from '../../actions/authActions';
import { getUserProfile } from '../../actions/userActions';
import ChangePassword from '../../components/user/changePassword';
import ManageSecretQuestions from '../../components/user/manageSecretQuestions';
import AcceptTermsOfUse from '../../components/user/acceptTermsOfUse';
import './firstLoginView.css'

export class FirstLoginView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: '',
            isPageLoading: false,
            showSecretQuestions: false,
            showChangePassword: props.showChangePassword,
            isTermsOfUseAccepted: props.isTermsOfUseAccepted,
            secretQuestionInfo: {},
            passwordInfo: {},
            changePasswordResponse: {
                succeeded: false,
                errors: [],
            },
        }

        this.loadUserProfile = this.loadUserProfile.bind(this);
        this.logout = this.logout.bind(this);
        this.handleSecretQuestionSubmit = this.handleSecretQuestionSubmit.bind(this);
        this.handleTermsOfUseSubmit = this.handleTermsOfUseSubmit.bind(this);
        this.saveAll = this.saveAll.bind(this);
    }

    handleSecretQuestionSubmit = (secretQuestionInfo) => {
        this.setState({
            secretQuestionInfo,
            showSecretQuestions: false,
            showChangePassword: true,
        });
    }

    handleTermsOfUseSubmit = (isAccepted) => {
        if (isAccepted) {
            this.setState({
                isTermsOfUseAccepted: isAccepted,
                showSecretQuestions: true,
                showChangePassword: false,
            });
        } else {
            this.logout();
        }
    }

    saveAll = (currentPassword, newPassword, confirmPassword) => {
        this.setState({ isPageLoading: true });
        var passwordInfo = {
            currentPassword: currentPassword,
            newPassword: newPassword,
            confirmPassword: confirmPassword,
        };

        var secretQuestionInfo = {
            secretQuestion1: this.state.secretQuestionInfo.secretQuestion1 ? this.state.secretQuestionInfo.secretQuestion1 : this.props.details.secretQuestion1,
            secretAnswer1: this.state.secretQuestionInfo.secretAnswer1 ? this.state.secretQuestionInfo.secretAnswer1 : this.props.details.secretAnswer1,
            secretQuestion2: this.state.secretQuestionInfo.secretQuestion2 ? this.state.secretQuestionInfo.secretQuestion2 : this.props.details.secretQuestion2,
            secretAnswer2: this.state.secretQuestionInfo.secretAnswer2 ? this.state.secretQuestionInfo.secretAnswer2 : this.props.details.secretAnswer2,
            secretQuestion3: this.state.secretQuestionInfo.secretQuestion3 ? this.state.secretQuestionInfo.secretQuestion3 : this.props.details.secretQuestion3,
            secretAnswer3: this.state.secretQuestionInfo.secretAnswer3 ? this.state.secretQuestionInfo.secretAnswer3 : this.props.details.secretAnswer3,
        };

        this.props.dispatch(saveFirstLoginChanges(secretQuestionInfo, passwordInfo))
        .then((response) => {
            if (response.error) {
                this.setState({
                    error: 'There was an error saving the first login information.',
                    isPageLoading: false,
                });
            } else {
                if (!response.payload.data.succeeded) {
                    this.setState({
                        isPageLoading: false,
                        changePasswordResponse: {
                            succeeded: response.payload.data.succeeded,
                            errors: response.payload.data.errors,
                        },
                    })
                } else {
                    this.props.dispatch(routes.patientSearch());
                }
            }
        })
        .catch((err) => {
            this.setState({
                error: `Error: ${err}`,
                isPageLoading: false,
            });
        });
    }

    logout = () => {
        this.props.dispatch(logoutUser())
            .then((response) => {
                if (response.error) {
                    this.setState({ error: 'An error occurred while trying to log out.' });
                } else {
                    this.props.dispatch(routes.logoutUser());
                }
            })
            .catch((err) => {
                this.setState({ error: `Error: ${err}` });
            });
    }

    loadUserProfile = () => {
        this.setState({ isPageLoading: true })
        this.props.dispatch(getUserProfile())
        .then((response) => {
            if (response.error) {
                this.setState({
                    error: 'There was an error loading the user details.',
                    isPageLoading: false,
                });
            } else {
                this.setState({ isPageLoading: false });
            }
        })
        .catch((err) => {
            this.setState({
                error: `Error: ${err}`,
                isPageLoading: false,
            });
        });
    }

    componentDidMount() {
        this.loadUserProfile();
    }

    render() {
        return (
            <Container style={{padding: '50px'}}>
                {this.state.isPageLoading &&
                    <LoadingIndicator />
                }
                {!this.state.isPageLoading &&
                    <div>
                        {!this.state.isTermsOfUseAccepted &&
                            <Row>
                                <Col>
                                    <AcceptTermsOfUse onSubmit={this.props.externalAuth.enableSingleSignOn ? this.saveAll : this.handleTermsOfUseSubmit} onCancel={this.logout} />
                                </Col>
                            </Row>
                        }
                        {this.state.isTermsOfUseAccepted && this.state.showSecretQuestions && !this.props.externalAuth.enableSingleSignOn &&
                            <Row>
                                <Col>
                                    <ManageSecretQuestions details={this.props.details}
                                        onSubmit={this.handleSecretQuestionSubmit}
                                        onCancel={this.logout}
                                        questionLength={this.props.userInfoConfig.secretQuestion.questionLength}
                                        answerLength={this.props.userInfoConfig.secretQuestion.answerLength}
                                        editMode={true} />
                                </Col>
                            </Row>
                        }
                        {this.state.isTermsOfUseAccepted && this.state.showChangePassword && !this.props.externalAuth.enableSingleSignOn &&
                            <Row>
                                <Col>
                                    <ChangePassword
                                        onSubmit={this.saveAll}
                                        onCancel={this.logout}
                                        showCancel={true}
                                        response={this.state.changePasswordResponse}
                                        passwordConfig={this.props.passwordConfig}
                                    />
                                </Col>
                            </Row>
                        }
                    </div>
                }
            </Container>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        details: state.user.details,
        externalAuth: state.config.externalAuth,
        userInfoConfig: state.app.config.userInfo,
        passwordConfig: state.config.passwordConfig,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FirstLoginView);
