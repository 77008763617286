import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ForgotPassword from '../../components/user/forgotPassword';
import * as userActions from '../../actions/userActions';

class ForgotPasswordView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            isPageLoading: false,
            showSuccess: false,
            showError: false,
        }

        this.sendResetEmail = this.sendResetEmail.bind(this);
        this.goToLogin = this.goToLogin.bind(this);
    }

    sendResetEmail = (email) => {
        this.setState({ isPageLoading: true });
        this.props.actions.forgotPassword(email)
            .then((response) => {
                if (response.error) {
                    this.setState({
                        isPageLoading: false,
                        error: 'There was an error sending the forgot password email.',
                        showSuccess: false,
                        showError: true,
                    });
                } else {
                    this.setState({
                        isPageLoading: false,
                        error: '',
                        showSuccess: true,
                        showError: false,
                    });
                }
            })
            .catch((err) => {
                this.setState({
                    isPageLoading: false,
                    error: 'There was an error sending the forgot password email.',
                    showSuccess: false,
                    showError: true,
                });
            });
    }

    goToLogin = () => {
        this.props.history.push('/login');
    }

    render() {
        return (
            <div className='login'>
                <div className="loginBackground"></div>
                <ForgotPassword
                    onSubmit={this.sendResetEmail}
                    onCancel={this.goToLogin}
                    showSuccess={this.state.showSuccess && this.props.forgotPasswordActionResponse.succeeded}
                    showError={this.state.showError && !this.props.forgotPasswordActionResponse.succeeded}
                    logoUrl={this.props.logoUrl}
                    isSaving={this.state.isPageLoading}
                />
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        forgotPasswordActionResponse: state.user.forgotPasswordAction,
        logoUrl: state.config.instance.logoUrl,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(userActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordView)
