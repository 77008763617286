/**
 * @format
 */
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as authActions from '../actions/authActions';
import LoadingIndicator from '../components/loadingIndicator';
import LoginForm from '../components/loginForm';
import qs from 'qs';
import './login.scss';

const Login = (props) => {
	const location = useLocation();
	const [error, setError] = useState('');
	const [loggingIn, setLoggingIn] = useState(false);
	const [redirectToReferrer, setRedirectToReferrer] = useState(false);
	const [showLoadingIndicator, setShowLoadingIndicator] = useState(true);
	const { from } = location.state || { from: { pathname: '/' } };

	const handleSso = useCallback(() => {
		const returnUrl = from ? `${from.pathname || '/'}${from.search || ''}` : '/';
		const url = props.externalAuth.singleSignOnUrl || '/';
		window.location.href = `${url}?returnUrl=${encodeURIComponent(returnUrl)}`;
	}, [from, props.externalAuth.singleSignOnUrl]);

	useEffect(() => {
		if (!props.isAuthenticated && props.externalAuth.isAutoLoginEnabled) {
			handleSso();
		} else if (props.instance.isLoaded) {
			setShowLoadingIndicator(false);
		}
	}, [props.isAuthenticated, props.externalAuth, props.instance, handleSso]);

	useEffect(() => {
		let queryString = location && location.search ? location.search : null;
		let rawParams = qs.parse(queryString, { ignoreQueryPrefix: true });
		const optionalParams = {};
		Object.keys(rawParams).forEach((key) => {
			optionalParams[key.toLowerCase()] = rawParams[key];
		});
		props.actions.auth.setGRDebugContext({ debug: optionalParams.debug === 'on' })

	}, [props.actions, location]);

	const authenticateUser = (email, password) => {
		setLoggingIn(true);
		props.actions.auth
			.authenticateUser(email, password, props.instanceId)
			.then((response) => {
				if (response.error) {
					if (response.error.response.data && typeof response.error.response.data === 'string') {
						setError(response.error.response.data);
					} else {
						setError('An error occurred while trying to log in.');
					}
				} else {
					setRedirectToReferrer(true);
				}
			})
			.catch((err) => {
				setError('An error occurred while trying to log in.');
			})
			.finally(() => {
				setShowLoadingIndicator(false);
				setLoggingIn(false);
			});
	};

	if (redirectToReferrer && !loggingIn) {
		return <Redirect to={from} />;
	}
	return (
		<div className='login'>
			{showLoadingIndicator && <LoadingIndicator loadingMessage='Fetching Data...' />}
			{!showLoadingIndicator && (
				<>
					<div className="loginBackground"></div>
					<LoginForm
						onSubmit={authenticateUser}
						error={error}
						isLoading={loggingIn}
						logoUrl={props.logoUrl}
						externalAuth={props.externalAuth}
						location={props.location}
						handleSso={handleSso}
					/>
				</>
			)}
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	return {
		email: state.auth.email,
		externalAuth: state.config.externalAuth,
		instance: state.config.instance,
		instanceId: state.auth.productInstanceId,
		isAuthenticated: state.auth.isAuthenticated,
		isLoading: state.ajaxStatus.callsInProgressCount > 0,
		logoUrl: state.config.instance.logoUrl
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			auth: bindActionCreators(authActions, dispatch)
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
