//Third Party Dependencies
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';

//Specific Business and UI components
import * as authActions from '../actions/authActions';
import * as productAlertConfigActions from '../actions/productAlertConfigActions';
import ProductAlertBanner from '../components/common/productAlertBanner';
import '../components/loginForm.css';
import { productIds } from '../constants/productAlertConfigValues';

export class Logout extends React.Component {

	componentDidMount() {
		this.props.actions.productAlertConfig.getProductAlertConfig(productIds.RD2);
		this.props.actions.authActions.logoutUser(this.props.token);
		this.props.actions.authActions.clearAuthToken();
	}

	render() {
		return (
			<div className="login">
				<div className="loginBackground">
					<div className="loginWindow">
						{this.props.productAlertConfig.config?.isEnabled &&
						this.props.productAlertConfig.config?.alertMessage &&
						this.props.productAlertConfig.isActive && (
							<div className='loginAlertBanner'>
								<ProductAlertBanner config={this.props.productAlertConfig.config} />
							</div>
						)}
						<div className="loginForm">
							<img src={this.props.logoUrl} alt="Logo" className="loginLogo" />
							<p>You have successfully logged out.</p>
							<p>Please click <Link to="/">here</Link> to log back in to continue managing your patients.</p>
						</div>
					</div>
				</div>
			</div >
			)
	}
}

function mapStateToProps(state, ownProps) {
	return {
		token: state.auth.token,
		logoUrl: state.config.instance.logoUrl,
		productAlertConfig: state.productAlertConfig,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			authActions: bindActionCreators(authActions, dispatch),
			productAlertConfig: bindActionCreators(productAlertConfigActions, dispatch),
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
