import React from 'react'
import PropTypes from 'prop-types';
import {
    Alert,
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Row,
    InputGroup,
    InputGroupText,
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PrivacyPolicyAndTermsOfUseLinks from 'components/legal/privacyPolicyAndTermsOfUseLinks';
import config from '../../config';

export default class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            emailAddress: '',
        }

        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.cancel = this.cancel.bind(this);
        this.isValid = this.isValid.bind(this);
    }

    isValid = () => {
        return this.state.emailAddress && this.state.emailAddress.trim() !== '';
    }

    handleChange = (event) => {
        const { name, value } = event.target
        this.setState({ [name]: value });
    }

    onSubmit = (event) => {
        event.preventDefault();
        if (this.isValid) {
            this.props.onSubmit(this.state.emailAddress);
        }
    }

    cancel = (event) => {
        event.preventDefault();
        this.props.onCancel();
    }

    render() {
        const alertStyle = {
            width: '100%',
            textAlign: 'center',
        }

        const successMessage = "Request received! If this is a recognized email address, you should receive a password reset email within 5-10 minutes. If you don't receive your email after this time, please contact MHD Support.";
        const errorMessage = "Sorry! Please contact MHD Support to reset your password.";

        return (
            <div className="loginWindow">
                <Form onSubmit={this.onSubmit} className="loginForm">
                    <img src={this.props.logoUrl} alt="Logo" className="loginLogo" />
                    {!this.props.showSuccess && !this.props.showError &&
                        <>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupText
                                        style={{
                                            border: '1px solid #ced4da',
                                            borderRadius: '.25rem',
                                            borderRight: 'none',
                                            marginRight: '-4px',
                                            padding: '0px',
                                            backgroundColor: 'transparent'
                                        }}
                                    >
                                        <span
                                            color="default"
                                            style={{
                                                cursor: 'inherit',
                                                margin: 'auto',
                                            }}
                                            className="btn btn-default btn-sm bg-transparent"
                                        >
                                            <FontAwesomeIcon icon="envelope" />
                                        </span>
                                    </InputGroupText>
                                    <Input
                                        type="email"
                                        id="emailAddress"
                                        name="emailAddress"
                                        placeholder="User Email Address"
                                        value={this.state.emailAddress}
                                        onChange={this.handleChange}
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup className="loginButton">
                                <Button
                                    type="submit"
                                    color="primary"
                                    className="experian-btn"
                                    block
                                    disabled={!this.isValid()}
                                >
                                    {this.props.isSaving ? (
                                        <span>
                                            <FontAwesomeIcon icon="spinner" spin /> Generating Email...
                                        </span>
                                    ) : (
                                        <span>Generate Password Reset Email</span>
                                    )}
                                </Button>
                            </FormGroup>
                            <FormGroup className="loginButton">
                                <Button
                                    color="secondary"
                                    block
                                    onClick={this.cancel}
                                >
                                    Cancel
                                </Button>
                            </FormGroup>
                        </>
                    }
                    {(this.props.showSuccess || this.props.showError) &&
                        <Row style={{ marginBottom: '6px' }}>
                            <Col>
                                <Alert
                                    color={this.props.showSuccess ? "success" : "danger"}
                                    style={alertStyle}
                                >
                                    {this.props.showSuccess ? successMessage : errorMessage}
                                </Alert>
                                <Button
                                    color="primary"
                                    className="experian-btn"
                                    block
                                    onClick={this.props.onCancel}
                                >
                                    Return to Login
                                </Button>
                            </Col>
                        </Row>
                    }
                    <div className="loginFooter">
                        <PrivacyPolicyAndTermsOfUseLinks />
                        <div className="text-center loginFooterSize">
                            @ {new Date().getFullYear()} MyHealthDirect, Inc. All Rights Reserved.
                        </div>
                    </div>
                    <div className="text-center loginContactUs">
                        Need Help? Please contact us at (629) 777-5552 |{' '}
                        {config.version}
                    </div>
                </Form>
            </div>
        )
    }
}

ForgotPassword.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    showSuccess: PropTypes.bool,
    showError: PropTypes.bool,
}
