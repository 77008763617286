import {
    Badge,
    Nav,
    NavItem,
    NavLink,
} from 'reactstrap';
import classnames from 'classnames';
import { PatientInfoTabIds } from "./patientInfoTabbedCollapsable";
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

const PatientInfoNav = (
    {
        activeTab,
        setActiveTab,
        patientConfig,
    }: {
        activeTab: any,
        setActiveTab: any,
        patientConfig: any,
    }
) => {
    const careOrders = useSelector((state: any) => state.activePatient.careOrderHistory);
    const openCareOrders = careOrders?.filter((careOrder: any) =>
        (careOrder.statusCode !== 'C')
        && (!careOrder.expirationDate || dayjs(careOrder.expirationDate) >= dayjs())
    );

    const openCareOrdersCount = openCareOrders?.length;

    return (

        <Nav tabs>
            <NavItem>
                <NavLink
                    className={classnames('patientInfoNavLink', { active: activeTab === PatientInfoTabIds.PatientDetails })}
                    onClick={() => setActiveTab(PatientInfoTabIds.PatientDetails)}
                >
                    Patient Details
                </NavLink>
            </NavItem>
            {patientConfig.isPatientHistoryEnabled && patientConfig.showPatientHistoryInPatientInfoPanel && (
                <NavItem>
                    <NavLink
                        className={classnames('patientInfoNavLink', { active: activeTab === PatientInfoTabIds.AppointmentHistory })}
                        onClick={() => {
                            if (activeTab !== PatientInfoTabIds.AppointmentHistory) {
                                setActiveTab(PatientInfoTabIds.AppointmentHistory);
                            }
                        }}
                    >
                        Appointment History
                    </NavLink>
                </NavItem>
            )}
            {patientConfig.isPatientCareOrderHistoryEnabled && (
                <NavItem>
                    <NavLink
                        className={classnames('patientInfoNavLink', { active: activeTab === PatientInfoTabIds.CareOrderHistory })}
                        onClick={() => {
                            if (activeTab !== PatientInfoTabIds.CareOrderHistory) {
                                setActiveTab(PatientInfoTabIds.CareOrderHistory);
                            }
                        }}
                    >
                        Open Care Orders
                        {openCareOrdersCount > 0 && (
                            <>
                                {' '}
                                <Badge
                                    id="openCareOrdersBadge"
                                    pill
                                >
                                    {openCareOrdersCount}
                                </Badge>
                            </>
                        )}
                    </NavLink>
                </NavItem>
            )}
            {patientConfig.isDecisionSupportWorkflowHistoryEnabled && (
                <NavItem>
                    <NavLink
                        className={classnames('patientInfoNavLink', { active: activeTab === PatientInfoTabIds.DecisionSupportWorkflowHistory })}
                        onClick={() => {
                            if (activeTab !== PatientInfoTabIds.DecisionSupportWorkflowHistory) {
                                setActiveTab(PatientInfoTabIds.DecisionSupportWorkflowHistory);
                            }
                        }}
                    >
                        Open Workflows
                    </NavLink>
                </NavItem>
            )}
        </Nav>
    )
};

export default PatientInfoNav;