import {
    Button,
    Col,
    Row,
} from 'reactstrap';
import { AddressDisplay, LabeledField } from "components/common/standardComponents";
import dayjs from "dayjs";
import { PhoneNumber } from "components/common/phoneNumber";

const PatientDetailsSummary = (
    { patientDetails, patientConfig, setShowConfirmMorePatientInfoModal }:
        { patientDetails: any, patientConfig: any, setShowConfirmMorePatientInfoModal: any }
) => (
    <>
        <Row style={{ marginTop: '10px' }}>
            <Col>
                <LabeledField
                    label={patientConfig.notes?.fieldLabel ?? 'Patient Notes'}
                    value={patientDetails?.notes}
                    isHidden={!patientConfig.notes.isVisible}
                />
            </Col>
        </Row>
        <Row>
            <Col>
                <LabeledField
                    label={patientConfig.memberId?.fieldLabel ?? 'Member Id'}
                    value={patientDetails?.memberId}
                    isHidden={!patientConfig.memberId.isVisible}
                />
            </Col>
            <Col>
                <LabeledField
                    label={patientConfig.groupNumber?.fieldLabel ?? 'Group Number'}
                    value={patientDetails?.groupNumber}
                />
            </Col>
            <Col>
                <LabeledField
                    label="DATE OF BIRTH"
                    value={patientDetails?.dateOfBirth ? dayjs(patientDetails?.dateOfBirth).format('MM/DD/YYYY') : '-'}
                />
            </Col>
        </Row>
        <Row>
            <Col>
                <LabeledField label="HOME PHONE" value={<PhoneNumber value={patientDetails?.homePhone} />} />
            </Col>
            <Col>
                <LabeledField label="MOBILE PHONE" value={<PhoneNumber value={patientDetails?.mobilePhone} />} />
            </Col>
            <Col>
                <AddressDisplay label="ADDRESS" address={patientDetails} />
            </Col>
        </Row>
        <Row>
            <Col>
                <LabeledField label="EMAIL" value={patientDetails?.email} />
            </Col>
            <Col></Col>
        </Row>
        <Row>
            <Col>
                <Button color="primary" onClick={() => setShowConfirmMorePatientInfoModal(true)}>
                    More Info
                </Button>
            </Col>
        </Row>
    </>
);

export default PatientDetailsSummary;