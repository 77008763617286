//Third Party Dependencies
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

//MHD General Purpose libraries
import LoadingIndicator from '../../components/loadingIndicator';
import { useAppHistory, useDataApiState, useVoidApiState } from 'hooks';
import { useAppSelector } from 'store';

//Specific Business and UI components
import { PasswordConfigInfo, VerifyUserTokenInfo, VerifyUserTokenResult } from 'types';
import { internalService } from 'services';
import ResetPassword from '../../components/user/resetPassword';
import ErrorSummary from '../../components/common/errorSummary';

export const ResetPasswordView = () => {
    const [verifyUserTokenInfo, setVerifyUserTokenInfo] = useState<VerifyUserTokenInfo>();

    const [verifyTokenResult, setVerifyToken] = useDataApiState<VerifyUserTokenResult>();
    const [resetUserPassword, setResetUserPassword] = useVoidApiState();

    const history = useAppHistory();
    const location = useLocation();
    const passwordConfig = useAppSelector((s: any) => s.config.passwordConfig) as PasswordConfigInfo;
    const logoUrl = useAppSelector((s: any) => s.config.instance.logoUrl) as string;

    useEffect(() => {
        const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as qs.ParsedQs;
        const verifyUserInfo: VerifyUserTokenInfo = {
            userId: params.userid as string,
            token: params.token as string,
        };
        setVerifyUserTokenInfo(verifyUserInfo);
        internalService.verifyToken(verifyUserInfo, setVerifyToken);
    }, [location.search, setVerifyToken]);

    const handleSubmit = (newPassword: string, confirmPassword: string) => {
        if (!verifyUserTokenInfo) {
            return;
        }
        const data = {
            newPassword,
            confirmPassword,
            token: verifyUserTokenInfo.token,
        };
        internalService.resetUserPassword(verifyUserTokenInfo.userId, data, setResetUserPassword);
    };

    const goToLogin = () => {
        history.navigate('/login');
    };

    const isPageLoading = verifyTokenResult.loading;
    const isVerified = verifyTokenResult.data?.isVerified ?? false;
    const showResetPasswordForm = !isPageLoading && isVerified;

    return (
        <div className='login'>
            {isPageLoading &&
                <LoadingIndicator />
            }
            {!isPageLoading &&
                <>
                    <div className="loginBackground"></div>
                    <div className="loginWindow">
                        {verifyTokenResult.error && <ErrorSummary error={verifyTokenResult.error} />}
                        {resetUserPassword.error && <ErrorSummary error={resetUserPassword.error} />}
                        {showResetPasswordForm &&
                            <ResetPassword
                                passwordConfig={passwordConfig}
                                onSubmit={handleSubmit}
                                onCancel={goToLogin}
                                email={verifyTokenResult.data?.email ?? ''}
                                logoUrl={logoUrl}
                                isSaving={resetUserPassword.processing}
                                isCompleted={resetUserPassword.completed}
                            />
                        }
                    </div>
                </>
            }

        </div>
    );
};

export default ResetPasswordView;
