import React from 'react';
import PropTypes from 'prop-types';
import { 
    Button,
    Col,
    Container,
    Input,
    InputGroup,
    InputGroupText,
    Row,
} from 'reactstrap';
import TermsOfUse from '../legal/termsOfUse';
import './acceptTermsOfUse.css'

class AcceptTermsOfUse extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isAccepted: false,
        };

        this.save = this.save.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    handleCheckboxChange = (event) => {
        const {name, checked } = event.target;
        this.setState({
            [name]: checked,
        })
    }

    save = (event) => {
        event.preventDefault();
        this.props.onSubmit(this.state.isAccepted);
    }

    cancel = (event) => {
        event.preventDefault();
        this.props.onCancel(event);
    }

    render() {
        var acceptanceVerbiage = {
            marginLeft: '10px',
            backgroundColor: 'transparent',
            border: 'none',
        };

        return (
            <div style={{marginBottom: '30px'}}>
                <Container>
                    <Row>
                        <Col>
                            <p className="text-center fw-bold header-text" >MyHealthDirect Terms of Use</p>
                            <hr></hr>
                            <TermsOfUse />
                        </Col>
                    </Row>
                    <div className="d-flex flex-row-reverse">
                        <div className="p-2">
                            <InputGroup>
                                <Input addon type="checkbox" name="isAccepted" value={this.state.isAccepted} onChange={this.handleCheckboxChange} />
                                <InputGroupText style={acceptanceVerbiage}>I have read and accept the Terms of Use</InputGroupText>
                                <Button className='spacing' color="primary" onClick={this.save} disabled={!this.state.isAccepted}>Save</Button>
                                <Button className='spacing' onClick={this.cancel}>Cancel</Button>
                            </InputGroup>
                        </div>
                    </div>
                </Container>
            </div>
        )
    }
}

AcceptTermsOfUse.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}

export default AcceptTermsOfUse
